<template>
  <div class="component-root" id="sign-form">
    <el-form
      ref="signForm"
      label-position="left"
      label-width="64px"
      :model="signForm"
      :rules="rules"
    >
    <el-form-item label-width="0px">
      <span>注册</span>
    
    </el-form-item>
      <el-form-item prop="userName" label="用户名">
        <el-input
          v-model="signForm.userName"
          :placeholder="placeholder.userName"
        >
        </el-input>
      </el-form-item>

      <el-form-item prop="account" label="ID">
        <el-input v-model="signForm.account" :placeholder="placeholder.account"></el-input>
      </el-form-item>
      <el-form-item  :label="placeholder.role">
        <el-radio-group v-model="signForm.role" size="small">
          <el-radio label="org">机构/公司</el-radio>
          <el-radio label="gov">政府/地区</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-form-item prop="password" label="密码">
        <el-input
          v-model="signForm.password"
          :placeholder="placeholder.password"
          type="password"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item prop="password1" >
        <el-input
          v-model="signForm.password1"
          :placeholder="placeholder.password1"
          type="password"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label-width="0px">
          <el-button  type="primary" @click="submit()">注册</el-button>
          <el-button @click="this.$router.replace({name:'登录'})" >取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {sign}from "network/login.js"

export default {
    name:"SignFrorm",
    data(){
    return{
        signForm:{
            userName:null,
            account:null,
            password:null,
            password1:null,
            role:'org',
        },
        placeholder:{
            userName:'用户名',
            account:'ID',
            password:'密码',
            password1:'确认密码',
            role:'类型'          
        },
        rules:{
            userName:[
                {required:true,message:'不能为空',trigger: 'blur'}
            ],
            account:[{required:true,message:'不能为空',trigger:'blur'}],
            password:[
                {validator:this.passwordvalid, trigger:'blur'}
            ],
            password1:[
                {validator:this.repidvalid , trigger:'blur'}
            ]
        },
       
    }

    },
    methods:{

        submit(){
            let refSignForm=this.$refs.signForm
            if(refSignForm)
            refSignForm.validate((valid)=>{
                if(valid)
                {
                    sign(this.signForm.userName,this.signForm.account,
                         this.signForm.password,this.signForm.role).then(
                             res=>{
                                 if(res){
                                  this.$message.success("注册成功")
                                  this.$router.replace({name:'登录',query:{Id:this.signForm.account,pw:this.signForm.password}})
                                 }
                             },
                             err=>{
                                 this.$message.error("注册失败")
                             }
                         )
                }else{
                    this.$message.warning('校验不通过')
                }
            }); 
        },
        

         repidvalid(rule,value,callback){
            if(value==undefined||value=="")
            callback(new Error("请确认密码"))
         if(value!=this.signForm.password)
                callback(new Error("不一致"))
          callback()
        },
        passwordvalid:(rule,value,callback)=>{
            if(!value)
            callback(new Error('不能为空'))
            if(value.length<6)
            callback(new Error("密码不少于6位"))
            if(!/\w/.test(value))
            callback(new Error("至少包含一位数字或字母"))
          callback()
        }
    }
}
</script>
<style lang="less" scoped>
#sign-form{
    width: 334px !important;
    // height: 294px !important;
    border-radius: 8px;
    box-shadow: var(--el-box-shadow-base);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    .el-form{
        width:60%;
        flex:0 0 ;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
}



</style>