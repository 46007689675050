import Instance from "./axios"


const login=(user,password)=>{
    return  Instance.post('/website/login',null,{
        auth: {
            username: user,
            password: password
          },
    })
}

const sign=(info,accountId,password,role)=>{
    return Instance.post('/users/add',
    {
        accountId,
        info,
        password,
        role
    })
}
export {login,sign}